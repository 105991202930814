<template>
    <section class="section">
        <h1><i class="bi bi-lock-fill text-primary"></i> パスワードを忘れた方へ</h1>

        <p>ご登録のメールアドレスを入力してください。パスワード再設定メールをお送りします。</p>

        <form class="form" @submit.prevent="remind()">
            <div class="form-row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>メールアドレス</label>
                    <input type="email" class="form-control" required="required" v-model="email">
                </div>
            </div>
            <div class="form-row">
                <button-exec-edit text="メール送信" />
            </div>
        </form>
        <div class="mt-5">ご登録メールアドレスが不明な方は <a href="https://docs.google.com/forms/d/e/1FAIpQLSchABWX2eRFcatjlaBDndS2zCnl6p9d5-2WdCkHFHgdKfM_NQ/viewform" target="_blank">お問合せ</a> ください。</div>
    </section>
</template>

<script>
import axios from 'axios';
import ButtonExecEdit from '@/shared/components/design/buttons/ButtonExecEdit.vue';

export default {
    components: {
        ButtonExecEdit,
    },
    name: 'PageReminder',
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
        'fetchUsers',
        'fetchErrorCount',
    ],
    data() {
        return {
            email: null,
        }
    },
    mounted() {
        // ログインしていたらPageHomeへリダイレクト
        if (this.$store.state.user.auth.is_signed_in) {
            this.$router.push({name: 'PageHome'});
        }
    },
    methods: {
        remind() {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_BASE_URI + '/reminder', {
                email: this.email,
            })
            .then(() => {
                this.showMessage('パスワード再設定メールを送信しました。');
            })
            .catch(() => {
                this.showErrorMessage('エラーが発生しました。');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
